import * as VetkuroTypes from '@sobocinski/vetkuro-types';

import { VehicleMedia, VehicleOptions } from './driver';
import { EventData } from './exploreQueries';
import { USER_ACTIVITY_KEY, USER_PROFILE_KEY, USER_SAVED_TRACKS_KEY } from './queryKeys';
import { Track } from './track';
import { AccountType } from 'Consts/account';
import { httpClient } from 'Infrastructure/http/client';

import { SessionStat } from 'Services/sessions';

export interface LastSessionData {
    id: string;
    tributes: number;
    track: {
        id: number;
        track_name: string;
        layout_name: string;
        layout_length: number;
    };
    stats: Record<VetkuroTypes.SessionStatType, number>;
    summary: Record<VetkuroTypes.SessionStatType, string>;
    stats_list: Array<SessionStat>;
    challenges_count: number;
    started: string;
    cover_image: string;
}

export interface LastVehicleData {
    created: string;
    id: string;
    manufacture: string;
    name: string;
    year: number;
    media: VehicleMedia[];
    options: VehicleOptions[];
    model: string;
}

export interface UserProfileDTO {
    id: string;
    avatar: string;
    birthday: string;
    first_name: string;
    username: string;
    last_name: string;
    nationality: string;
    timezone: string;
    account_type: AccountType;
    created: string;
    activity: {
        sessions: number;
        tracks: number;
        achievements: number;
        used_vehicles: number;
    };
    last_vehicles: LastVehicleData[];
    last_sessions: LastSessionData[];
    last_events: EventData[];
    last_visited_tracks: Track[];
}

export interface UserActivity {
    sessions_completed: number;
    tracks_visited: number;
    events_participated: number;
    achievements: number;
    vehicles_used: number;
}

export const getUserProfile = async () => {
    const response = await httpClient.get(`/drivers/profile`);
    return response.data;
};

export const getUserProfileQuery = () => ({
    queryKey: [USER_PROFILE_KEY],
    queryFn: async () => getUserProfile(),
});

export const getUserSavedTrackListQuery = () => ({
    queryKey: [USER_SAVED_TRACKS_KEY],
    queryFn: async (): Promise<Track[]> => {
        const response = await httpClient.get(`/tracks/user/saved`);
        return response.data;
    },
});

export const getUserActivitiesQuery = (userSlug: string) => ({
    queryKey: [USER_ACTIVITY_KEY, userSlug],
    queryFn: async (): Promise<UserActivity> => {
        const response = await httpClient.get(`drivers/${userSlug}/activity`);
        return response.data;
    },
});
